import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { HEADER_HEIGHT } from 'components/Header';
import Layout from 'components/Layout';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';
import HowItWork from 'components/Products/SmartCustomerAcquisition/HowItWork';
import WhyChooseUs from 'components/Products/SmartCustomerAcquisition/WhyChooseUs';
import * as themes from 'themes';
import ItemList from 'components/ItemList/Simple';
import { graphql } from 'gatsby';
import breakpoints from 'utils/breakpoints';
import Img from 'components/Img';

const Intro = styled.section`
  background-color: #f4f9ff;
  padding-top: ${HEADER_HEIGHT + 70}px;
  padding-bottom: 50px;
  text-align: center;
  @media screen and (min-width: ${breakpoints.md}) {
    padding-bottom: 70px;
    text-align: left;
  }
`;

const StyledItemList = styled(ItemList)`
  margin-top: 30px;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-top: 40px;
  }
`;

const IntroImg = styled(Img)`
  width: 100%;
`;

function SmartCustomerAcquisitionProductPage({ className, data }) {
  const { intro, whyChooseUs, howItWorks } = data.smartCustomerAcquisitionJson;
  return (
    <Layout
      className={className}
      fullSize
      headerTheme={themes.light}
      pageTitle="Smart Customer Acquisition"
    >
      <Intro>
        <Container>
          <Row justify="center">
            <Col xs={6}>
              <IntroImg alt={intro.title} publicURL={intro.image} />
            </Col>
            <Col xs={10} md={5} offset={{ md: 1 }}>
              <Heading title={intro.title} />
              <Paragraph>{intro.description}</Paragraph>
              <StyledItemList heading={intro.listTitle} items={intro.list} />
            </Col>
          </Row>
        </Container>
      </Intro>
      <HowItWork {...howItWorks} />
      <WhyChooseUs {...whyChooseUs} />
    </Layout>
  );
}

SmartCustomerAcquisitionProductPage.defaultProps = {
  className: null,
};

SmartCustomerAcquisitionProductPage.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    smartCustomerAcquisitionJson: PropTypes.shape({
      intro: PropTypes.shape({
        title: PropTypes.string,
        image: PropTypes.string,
        description: PropTypes.string,
        listTitle: PropTypes.string,
        list: ItemList.propTypes.items,
      }),
      howItWorks: PropTypes.shape({}),
      whyChooseUs: PropTypes.shape({}),
    }),
  }).isRequired,
};

export const query = graphql`
  {
    smartCustomerAcquisitionJson {
      intro {
        title
        description
        image
        listTitle
        list {
          iconType
          description
        }
      }
      whyChooseUs {
        title
        list {
          iconType
          description
        }
      }
      howItWorks {
        title
        sections {
          image
          title
          descriptions
        }
      }
    }
  }
`;

export default SmartCustomerAcquisitionProductPage;
