import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Row, Col, useScreenClass } from 'react-grid-system';
import Markdown from 'react-markdown';
import Img from 'components/Img';
import SectionHeading from 'components/Heading/SubHeading';
import Paragraph from 'components/Paragraph';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.section`
  & + & {
    padding-top: 40px;

    @media screen and (min-width: ${breakpoints.md}) {
      padding-top: 90px;
    }
  }
`;
const ImgWrap = styled.div`
  text-align: center;
  height: 100%;
  position: relative;
  span {
    position: relative;
    z-index: 2;
  }
  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    width: 3px;
    height: calc(100% + 40px);
    background: #f4f9ff;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  section:last-child &::before {
    content: none;
  }
  @media screen and (min-width: ${breakpoints.md}) {
    &::before {
      content: none;
    }
  }
`;

const StyledImg = styled(Img)`
  width: 55px;

  @media screen and (min-width: ${breakpoints.md}) {
    margin: 0;
    width: 100%;
    max-width: 417px;
    margin-bottom: 40px;
  }
`;

function Section({
  className,
  title,
  description,
  descriptions,
  image,
  imageLeft,
}) {
  const img = (
    <ImgWrap>
      <StyledImg alt={title} publicURL={image} />
    </ImgWrap>
  );
  const content = (
    <>
      {title && <SectionHeading>{title}</SectionHeading>}
      {description && <Paragraph source={description} as={Markdown} />}
      {descriptions &&
        descriptions.map(des => (
          <Paragraph key={des} source={des} as={Markdown} />
        ))}
    </>
  );
  const screenClass = useScreenClass();
  const rowAlignment = ['xs', 'sm'].includes(screenClass)
    ? 'stretch'
    : 'center';
  if (imageLeft) {
    return (
      <Wrapper className={className}>
        <Container>
          <Row align={rowAlignment}>
            <Col xs={3} md={6} push={{ md: 6 }}>
              {img}
            </Col>
            <Col xs={8} md={4} pull={{ md: 4 }}>
              {content}
            </Col>
          </Row>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className}>
      <Container>
        <Row align={rowAlignment}>
          <Col xs={3} md={6}>
            {img}
          </Col>
          <Col xs={8} md={4}>
            {content}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

Section.defaultProps = {
  className: null,
  title: null,
  description: null,
  image: null,
  imageLeft: false,
  descriptions: null,
};

Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  descriptions: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string,
  imageLeft: PropTypes.bool,
};

export default Section;
