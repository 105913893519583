import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/ContentIcon';
import Paragraph from 'components/Paragraph';
import Markdown from 'react-markdown';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const Item = styled.li`
  padding: 0;
  margin: 0 0 50px 0;
  text-align: center;

  @media screen and (min-width: ${breakpoints.md}) {
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 0;
    & + & {
      margin-top: 20px;
    }
  }
`;

const Content = styled.article`
  padding: 0 0 0 20px;
  flex: 1;
`;

const Heading = styled.h4`
  color: #1c2f45;
  font-weight: bold;
  margin-bottom: 22px;
`;

const StyledIcon = styled(Icon)`
  width: 84px;
  height: 90px;
`;

function ItemListSimple({ className, items, heading }) {
  return (
    <Wrapper className={className}>
      {heading && <Heading>{heading}</Heading>}
      {items.map(item => (
        <Item key={item.description}>
          <StyledIcon type={item.iconType} />
          <Content>
            {item.description && (
              <Paragraph as={Markdown} source={item.description} />
            )}
          </Content>
        </Item>
      ))}
    </Wrapper>
  );
}

ItemListSimple.defaultProps = {
  className: null,
  heading: null,
  items: [],
};

ItemListSimple.propTypes = {
  heading: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      iconType: Icon.propTypes.type,
      description: PropTypes.string,
    })
  ),
};

export default ItemListSimple;
