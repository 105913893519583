import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

import Heading from 'components/Heading';
import Section from './Section';

const Wrapper = styled.section`
  margin: 0;
  background: #fff;
  padding-top: 70px;
  padding-bottom: 70px;

  @media screen and (min-width: ${breakpoints.md}) {
    padding-top: 100px;
    padding-bottom: 140px;
  }
`;

function HowItWork({ className, title, sections }) {
  return (
    <Wrapper className={className}>
      <Heading centered title={title} />
      {sections.map((section, i) => (
        <Section imageLeft={i % 2 === 0} {...section} />
      ))}
    </Wrapper>
  );
}

HowItWork.defaultProps = {
  className: null,
};

HowItWork.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape(Section.propTypes)).isRequired,
};

export default HowItWork;
