import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';

import Heading from 'components/Heading';
import ItemList from 'components/ItemList/Simple';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.section`
  background: #f4f9ff;
  text-align: center;
  padding: 30px 0;
  @media screen and (min-width: ${breakpoints.md}) {
    padding: 100px 0;
    text-align: left;
  }
`;

const StyledItemList = styled(ItemList)`
  margin-top: 36px;
  @media screen and (min-width: ${breakpoints.md}) {
    text-align: left;
  }
`;

function WhyChooseUs({ className, title, list }) {
  return (
    <Wrapper className={className}>
      <Container>
        <Row justify="center">
          <Col md={5}>
            <Heading title={title} />
          </Col>
          <Col md={5}>
            <StyledItemList items={list} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

WhyChooseUs.defaultProps = {
  className: null,
};

WhyChooseUs.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  list: ItemList.propTypes.items.isRequired,
};

export default WhyChooseUs;
